<template>
    <router-view>
    </router-view>
</template>

<script>

    export default {
        name: "Declration",
    };
</script>
